<template>
  <div id="TransferenciaModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Transferência</span>
          <v-btn
            icon
            dark
            color="#F2F6F7"
            @click="fechar()">
            <v-icon large class="title">
              mdi-close
            </v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1">
        <v-container>
          <v-form 
            class="form-container"
            ref="form"
            v-model="valid">
            <v-row>
              <v-col 
                cols="12"
                class="px-0">
                <v-autocomplete
                  v-model="dados.cod_pessoa_conta_origem"
                  :items="opcoes_conta"
                  label="Conta Origem"
                  item-text="pessoa_conta_descricao" 
                  item-value="cod_pessoa_conta"
                  filled 
                  outlined 
                  :readonly="obj_extrato_movimento.debito_credito === 'D'"
                  dense 
                  :rules="notEmptySelectRule"
                  background-color="#FFF" 
                  required/>

                <v-autocomplete
                  v-model="dados.cod_pessoa_conta_destino"
                  :items="opcoes_conta_destino"
                  label="Conta Destino"
                  item-text="pessoa_conta_descricao" 
                  item-value="cod_pessoa_conta"
                  filled 
                  outlined 
                  dense 
                  :rules="notEmptySelectRule"
                  background-color="#FFF" 
                  required/>
                
                <v-autocomplete
                  v-model="dados.centrocusto"
                  label="Centro de Custo"
                  filled
                  dense
                  outlined
                  clearable
                  background-color="#FFF"
                  :items="array_centro_custo"
                  return-object
                  item-text="centrocusto_descricao"
                  item-key="cod_centrocusto" >
                  <template #append-outer>
                    <v-icon
                      class="pointer"
                      style="cursor: pointer"
                      color="green darken-1"
                      @click="cadastroCentroCusto()">mdi-plus
                    </v-icon>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-model="dados.planoconta"
                  label="Plano de Conta"
                  filled
                  dense
                  outlined
                  return-object
                  background-color="#FFF"
                  :items="array_plano_conta"
                  clearable
                  item-text="planoconta_descricao"
                  item-key="cod_planoconta" >
                  <template #append-outer>
                    <v-icon
                      class="pointer"
                      style="cursor: pointer"
                      color="green darken-1"
                      @click="cadastroPlanoConta()">mdi-plus
                    </v-icon>
                  </template>
                </v-autocomplete>

                <DatePicker
                  v-model="dados.cadastro_dt"
                  label="Data"
                  outlined
                  backgroundColor="#FFF"
                  dense
                  placeholder="dia/mês/ano"
                  readonly/>

                <VuetifyMoney
                  type="tel"
                  v-model="dados.valor"
                  filled
                  dense
                  outlined
                  background-color="#FFF"
                  placeholder="Valor *"
                  label="Valor *"
                  required
                  readonly
                  :rules="valorRules" />

                <v-text-field
                  v-model="dados.observacao"
                  class="mb-6 "
                  light
                  background-color="#FFF"
                  label="Observação"
                  placeholder="Observação"
                  filled
                  autofocus
                  clearable
                  maxlength="100"
                  outlined
                  dense />

                 
                </v-col>
              </v-row>
            </v-form>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text>
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          :loading="loading"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
      <v-container v-if="dialog_CentroCustoModal">
      <v-dialog
          persistent
          v-model="dialog_CentroCustoModal"
          transition="dialog-bottom-transition"
          max-width="500">
        <CentroCustoModal
          :dialog_CentroCustoModal.sync="dialog_CentroCustoModal"
          @atualizarCentroCusto="opcoesCentroDeCusto"
        />
      </v-dialog>
    </v-container>
   
    <v-container v-if="dialog_PlanoContaModal">
      <v-dialog
          persistent
          v-model="dialog_PlanoContaModal"
          transition="dialog-bottom-transition"
          max-width="500">
        <PlanoContaModal
          :dialog_PlanoContaModal.sync="dialog_PlanoContaModal"
          :array_plano_conta="array_plano_conta"
          @atualizarPlanoConta="opcoesPlanoDeConta"
        />
      </v-dialog>
    </v-container>
    </v-card>
  </div>
</template>

<script>
import store_site from "../../../store/store_site"
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import { API } from '../../../services/API'
import CentroCustoModal from "../ContasPagar/CentroCustoModal.vue"
import PlanoContaModal from "../ContasPagar/PlanoContaModal.vue"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { COR_PRINCIPAL } from "../../../services/constantes";
import store_Pessoa from "../../../components/Pessoas/store_Pessoa";

export default {
  components: {
    VuetifyMoney,
    CentroCustoModal,
    PlanoContaModal,
    DatePicker,
  },

  name: "TransferenciaModal",

  props: {
    cedente_selecionado : { type: Object },
    obj_extrato_movimento : { type: Object },
    cod_extrato_movimento : { type: Number },
  },

  data() {
    return {
      
      store_site           : store_site,
      store_Pessoa         : store_Pessoa,
      COR_PRINCIPAL        : COR_PRINCIPAL,
      loading              : false,
      opcoes_conta         : null,
      opcoes_conta_destino : null,
      array_centro_custo : [],
      array_plano_conta  : [],
      dados : {
        cod_pessoa_conta_origem   : null,
        cod_pessoa_conta_destino  : null,
        centrocusto               : null,
        planoconta                : null,
        valor                     : null,
        observacoes               : null
      },
      valid: true,
      form: false,
      dialog_CentroCustoModal : false,
      dialog_PlanoContaModal  : false,
      descricaoRules: [
        (value) => !!value || "A Observação é obrigatória",
      ],

      notEmptyRule: [
        value => value?.length > 0  || 'Esse campo é obrigatório'
      ],
      notEmptySelectRule: [ 
        value => !!value || 'Esse campo é obrigatório'
      ],

      valorRules : [
        value => !!value || value
      ]
    };
  },

  mounted(){
    this.buscaContas()
    this.opcoesCentroDeCusto()
    this.opcoesPlanoDeConta()
    this.dados.valor  = Math.abs(this.obj_extrato_movimento.valor)
    this.dados.cadastro_dt = this.obj_extrato_movimento.data
  },

  methods: {
    cadastroCentroCusto(){
      this.dialog_CentroCustoModal = true
    },

    cadastroPlanoConta(){
      this.dialog_PlanoContaModal = true
    },

    async opcoesCentroDeCusto (item = null){
      let {data} = await API.get('/erp/centro_custo')
      this.array_centro_custo =  data.result

      if(!!item?.cod_centrocusto){
        this.dados.centrocusto = {...item}
      }
    },
    
    async opcoesPlanoDeConta (item = null){
      let {data} = await API.get('/erp/plano_conta')
      
      if(data.result.length > 0){
        this.array_plano_conta = data.result.map(elem => {
          return {
            cod_planoconta : elem.cod_planoconta,
            planoconta_codigocontabil : elem.planoconta_codigocontabil,
            planoconta_descricao : `${elem.planoconta_codigocontabil} - ${elem.planoconta_descricao}` 

          }
        })
      }

      if(!!item?.cod_planoconta){
        item.planoconta_descricao = `${item.planoconta_codigocontabil} - ${item.planoconta_descricao}`
        this.dados.planoconta = {...item}
      }
    },

    async buscaContas(){
      const {data} = await API.get('/conta_pessoa/',
      { 
        params: { pessoa_conta_tipo : 'B' } 
      })
     
      var conta
      if(this.obj_extrato_movimento.debito_credito === 'D'){
        conta = data?.result.find(value => value.cod_pessoa_conta === this.cedente_selecionado?.cod_pessoa_conta)
        this.opcoes_conta = [conta]
        this.dados.cod_pessoa_conta_origem = conta.cod_pessoa_conta
        let index = data?.result.findIndex(value => value.cod_pessoa_conta === this.cedente_selecionado?.cod_pessoa_conta)
        this.opcoes_conta_destino = data?.result
        if(index > 0){
          this.opcoes_conta_destino.splice(index, 1)
        }
      }
      else{
        conta = data?.result.find(value => value.cod_pessoa_conta === this.cedente_selecionado?.cod_pessoa_conta)
        this.opcoes_conta_destino = [conta]
        this.dados.cod_pessoa_conta_destino = conta.cod_pessoa_conta
        let index = data?.result.findIndex(value => value.cod_pessoa_conta === this.cedente_selecionado?.cod_pessoa_conta)
        this.opcoes_conta = data?.result
        if(index > 0){
          this.opcoes_conta.splice(index, 1)
        }
      }
    },

    fechar(){
      this.$emit("update:dialog_TransferenciaModal", false);
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      // return
      if (lb_valido) {

        this.dados.cod_centrocusto        = this.dados.centrocusto?.cod_centrocusto || null
        this.dados.cod_planoconta         = this.dados.planoconta?.cod_planoconta || null
        this.dados.valor                  = Number(this.dados.valor) || null
        this.dados.cod_extrato_movimento  = this.obj_extrato_movimento?.cod_extrato_movimento || null
        this.dados.debito_credito         = this.obj_extrato_movimento?.debito_credito || null
        delete this.dados.centrocusto
        delete this.dados.planoconta
        if (this.dados.valor === 0 || !this.dados.valor) {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = 'O Valor não pode ser 0 ou vazio!'
          this.store_site.alert           = true;
          return 
        }

        this.loading = true

        //console.log("🚀 ~ dados:", this.dados)

        let ls_Rel = await API.post(`/erp/contas_transferencia`, JSON.stringify(this.dados));
        
        if (ls_Rel.status == 200) {
          this.$emit('atualiza_extrato', true)
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Rel.data.result.trim();
          this.store_site.alert           = true;
        }

        this.fechar()
        this.loading = false
      }
    }
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>